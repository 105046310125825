<template>
  <div>

    <van-form :disabled="!(status == 0 || status == 2 || status == 4)" @submit="onSubmit">

      <van-field required name="photoUploader" label="照片（上传标准1寸证件照，支持jpg、png格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" class="photo" :preview-full-image="false"
            v-model="photoUploader" :max-count="1" :after-read="afterReadPhoto"
            :accept="isMobile ? '*' : '.jpg,.jpeg,.png'" @delete="deleteAlone('photo')" />
        </template>
      </van-field>

      <van-field name="graduateUploader" label="毕业证书（支持jpg、png、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" class="upload-image" :preview-full-image="false"
            v-model="graduateUploader" :max-count="1" :after-read="afterReadGraduate"
            :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.pdf'" @delete="deleteAlone('graduate')" />
        </template>
      </van-field>

      <van-field name="diplomaUploader" label="学位证书（支持jpg、png、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 4)" class="upload-image" :preview-full-image="false"
            v-model="diplomaUploader" :max-count="1" :after-read="afterReadDiploma"
            :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.pdf'" @delete="deleteAlone('diploma')" />
        </template>
      </van-field>

      <van-field name="english_levelUploader" label="英语等级证书（支持jpg、png、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" class="upload-image" :preview-full-image="false"
            v-model="english_levelUploader" :multiple="true" :max-count="100" :after-read="afterReadenglish_level"
            :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.pdf'" @delete="deleteEnglish_level" />
        </template>
      </van-field>

      <van-field name="mandarin_certificateUploader" label="普通话证书（支持jpg、png、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 4)" class="upload-image" :preview-full-image="false"
            v-model="mandarin_certificateUploader" :max-count="1" :after-read="afterReadmandarin_certificate"
            :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.pdf'" @delete="deleteAlone('mandarin_certificate')" />
        </template>
      </van-field>

      <van-field required name="overseas_experienceUploader" label="中文教学经历证明（支持jpg、png、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" class="upload-image" :preview-full-image="false"
            v-model="overseas_experienceUploader" :multiple="true" :max-count="100"
            :after-read="afterReadoverseas_experience" :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.pdf'"
            @delete="deleteOverseas_experience_new" />
        </template>
      </van-field>

      <van-field name="bilingualism_resumeuploader" label="中文简历（支持doc、docx、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" upload-icon="add" class="uploader-file"
            v-model="bilingualism_resumeUploader" :max-count="1" :after-read="afterReadbilingualism_resume"
            :accept="isMobile ? '*' : '.doc,.docx,.pdf'" @delete="deleteAlone('bilingualism_resume')" />
        </template>
      </van-field>

      <van-field name="english_resumeuploader" label="英文简历（支持doc、docx、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" upload-icon="add" class="uploader-file"
            v-model="english_resumeUploader" :max-count="1" :after-read="afterReadenglish_resume"
            :accept="isMobile ? '*' : '.doc,.docx,.pdf'" @delete="deleteAlone('english_resume')" />
        </template>
      </van-field>

      <van-field name="volunteer_certificateUploader" label="志愿者证书（支持jpg、png、doc、docx、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" upload-icon="add" class="uploader-file"
            :preview-full-image="false" v-model="volunteer_certificateUploader" :multiple="true" :max-count="100"
            :after-read="afterReadvolunteer_certificate" :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.doc,.docx,.pdf'"
            @delete="deleteAloneVolunteer_certificate_new" />
        </template>
      </van-field>

      <van-field name="teacher_certificateUploader" label="国际中文教师证书（支持jpg、png、doc、docx、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" upload-icon="add" class="uploader-file"
            :preview-full-image="false" v-model="teacher_certificateUploader" :max-count="1"
            :after-read="afterReadteacher_certificate" :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.doc,.docx,.pdf'"
            @delete="deleteAlone('teacher_certificate')" />
        </template>
      </van-field>

      <van-field name="driving_licenseUploader" label="驾照（支持jpg、png、doc、docx、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" upload-icon="add" class="uploader-file"
            :preview-full-image="false" v-model="driving_licenseUploader" :max-count="1"
            :after-read="afterReaddriving_license" :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.doc,.docx,.pdf'"
            @delete="deleteAlone('driving_license')" />
        </template>
      </van-field>


      <van-field name="no_criminal_recordUploader" label="无犯罪记录证明（支持jpg、png、doc、docx、pdf格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" upload-icon="add" class="uploader-file"
            :preview-full-image="false" v-model="no_criminal_recordUploader" :max-count="1"
            :after-read="afterReadno_criminal_record" :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.doc,.docx,.pdf'"
            @delete="deleteAlone('no_criminal_record')" />
        </template>
      </van-field>


      <van-field name="talent_showUploader" label="才艺展示（图片或视频，图片jpg、png，大小不超过30MB，视频mp4，不超过500MB）">
        <template #input>
         
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" class="upload-image upload-video" :preview-full-image="false" upload-icon="add"
            v-model="talent_showUploader" :multiple="true" :max-count="100" :after-read="afterReadtalent_show"
            :accept="isMobile ? '*' : '.jpg,.jpeg,.png,.mp4'"  @delete="deleteTalent_show" >
            <template #preview-cover="{file}">
              <div v-for="(item,i) in form.talent_show" :key="i">
                <video v-if="item.file_name && item.file_name.toLowerCase().indexOf('mp4') !== -1 && file.name == item.file_name" style="background:#000;" width="200" height="200" controls>
                  <source :src="item.file_url" type="video/mp4">
                  您的浏览器不支持 video 标签。
                </video>
              </div>
            </template>
          </van-uploader>
        </template>
      </van-field>

      <van-field name="other_materialuploader" label="其他证明材料（可以提交多个文件，支持excel、word、pdf、jpg、png、mp4格式）">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" :preview-full-image="false" upload-icon="add"
            v-model="other_materialUploader" :multiple="true" :max-count="100" :after-read="afterReadother_material"
            accept="*" @delete="deleteOther" />
        </template>
      </van-field>

      <div class="submit-content">
        <van-button v-if="active === 2" class="submit-btn" block type="info" native-type='button'
          @click.stop="handlePreStep"><span>上一步</span></van-button>
        <van-button class="submit-btn" block type="info" native-type="submit"><span>投递</span></van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
import { isPhone } from "@/libs/validate";
import * as qiniu from "qiniu-js"

export default {
  name: "index",
  props: {
    active: {}
  },
  data() {
    return {
      form: {
        photo: '',
        photo_title: '',
        graduate: '',
        graduate_title: '',
        diploma: '',
        diploma_title: '',
        english_level_new: [],
        english_level: '',
        english_level_title: '',
        mandarin_certificate: '',
        mandarin_certificate_title: '',
        overseas_experience_new: [],
        overseas_experience: '',
        overseas_experience_title: '',
        bilingualism_resume: '',
        bilingualism_resume_title: '',
        english_resume: '',
        english_resume_title: '',
        volunteer_certificate_new: [],
        volunteer_certificate: '',
        volunteer_certificate_title: '',
        teacher_certificate: '',
        teacher_certificate_title: '',
        driving_license: '',
        driving_license_title: '',
        no_criminal_record:'',
        no_criminal_record_title:'',
        talent_show:[],
        talent_show_title:'',
        other_material: [],
        other_material_title: []
      },
      photoUploader: [],
      graduateUploader: [],
      diplomaUploader: [],
      english_levelUploader: [],
      mandarin_certificateUploader: [],
      bilingualism_resumeUploader: [],
      english_resumeUploader: [],
      volunteer_certificateUploader: [],
      teacher_certificateUploader: [],
      driving_licenseUploader: [],
      no_criminal_recordUploader:[],
      talent_showUploader:[],
      other_materialUploader: [],
      overseas_experienceUploader: [],
      status: 0,
      loading: false
    };
  },
  created() { },
  mounted() {
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 992;
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.job_id) {
          this.getSign()
        }

      }
    },
    'active': {
      deep: true,
      handler() {
        this.getSign()
      }
    },
  },
  methods: {
    deleteAlone(type) {
      this.form[type] = ''
      this.form[`${type}_title`] = ''
    },
    deleteEnglish_level(delobj){
      this.form.english_level_new = this.form.english_level_new.filter(item => {
        return item.file_name !== delobj.file.name
      })
    },
    deleteOverseas_experience_new(delobj){
      this.form.overseas_experience_new = this.form.overseas_experience_new.filter(item => {
        return item.file_name !== delobj.file.name
      })
    },
    deleteAloneVolunteer_certificate_new(delobj){
      this.form.volunteer_certificate_new = this.form.volunteer_certificate_new.filter(item => {
        return item.file_name !== delobj.file.name
      })
    },
    deleteTalent_show(delobj) {
      this.form.talent_show = this.form.talent_show.filter(item => {
        return item.file_name !== delobj.file.name
      })
    },
    deleteOther(delobj) {
      this.form.other_material = this.form.other_material.filter(item => {
        return item.file_name !== delobj.file.name
      })
    },
    validatorPhone(val) {
      return isPhone(val);
    },
    getSign() {
      this.$api.SIGN_GET({ job_id: this.$route.query.job_id }).then(res => {
        if (res && res.id) {
          this.form = {
            ...res
          }

          this.status = res.status

          if (typeof res.other_material === 'string') {
            this.form.other_material = []
          }

          if (res.photo_url) {
            this.photoUploader = [{
              file: {
                name: res.photo_title,
                url: res.file_url
              },
              url: res.photo_url
            }]
          }
          if (res.graduate_url) {
            this.graduateUploader = [{
              file: {
                name: res.graduate_title,
                url: res.file_url
              },
              url: res.graduate_url
            }]
          }
          if (res.diploma_url) {
            this.diplomaUploader = [{
              file: {
                name: res.diploma_title,
                url: res.file_url
              },
              url: res.diploma_url
            }]
          }

          if (res.mandarin_certificate_url) {
            this.mandarin_certificateUploader = [{
              file: {
                name: res.mandarin_certificate_title,
                url: res.file_url
              },
              url: res.mandarin_certificate_url
            }]
          }

          if (res.bilingualism_resume_url) {
            this.bilingualism_resumeUploader = [{
              file: {
                name: res.bilingualism_resume_title,
                url: res.file_url
              },
              url: res.bilingualism_resume_url
            }]
          }


          if (res.english_resume_url) {
            this.english_resumeUploader = [{
              file: {
                name: res.english_resume_title,
                url: res.file_url
              },
              url: res.english_resume_url
            }]
          }




          if (res.teacher_certificate_url) {
            this.teacher_certificateUploader = [{
              file: {
                name: res.teacher_certificate_title,
                url: res.file_url
              },
              url: res.teacher_certificate_url
            }]
          }

          if (res.driving_license_url) {
            this.driving_licenseUploader = [{
              file: {
                name: res.driving_license_title,
                url: res.file_url
              },
              url: res.driving_license_url
            }]
          }

          if (res.no_criminal_record_url) {
            this.no_criminal_recordUploader = [{
              file: {
                name: res.no_criminal_record_title,
                url: res.file_url
              },
              url: res.no_criminal_record_url
            }]
          }

          


          if (typeof res.volunteer_certificate_new !== 'string' && res.volunteer_certificate_new && res.volunteer_certificate_new.length > 0) {
            this.volunteer_certificateUploader = res.volunteer_certificate_new.map(item => {
              return {
                file: {
                  name: item.file_name,
                  url: res.file_url
                },
                url: item.file_url,
              }
            })
          }


          if (typeof res.english_level_new !== 'string' && res.english_level_new && res.english_level_new.length > 0) {
            this.english_levelUploader = res.english_level_new.map(item => {
              return {
                file: {
                  name: item.file_name,
                  url: res.file_url
                },
                url: item.file_url,
              }
            })
          }


          if (typeof res.overseas_experience_new !== 'string' && res.overseas_experience_new && res.overseas_experience_new.length > 0) {
            this.overseas_experienceUploader = res.overseas_experience_new.map(item => {
              return {
                file: {
                  name: item.file_name,
                  url: res.file_url
                },
                url: item.file_url,
              }
            })
          }

          if (typeof res.talent_show !== 'string' && res.talent_show && res.talent_show.length > 0) {
            this.talent_showUploader = res.talent_show.map(item => {
              return {
                file: {
                  name: item.file_name,
                  url: res.file_url
                },
                url: item.file_url,
              }
            })
          }


          if (typeof res.other_material !== 'string' && res.other_material && res.other_material.length > 0) {
            this.other_materialUploader = res.other_material.map(item => {
              return {
                file: {
                  name: item.file_name,
                  url: res.file_url
                },
                url: item.file_url,
              }
            })

          }
        }
      })
    },
    handlePreStep() {
      if (this.active === 2) {
        this.$emit('updateActive', 1)
      }
    },

    onSubmit() {

      if (!this.form.photo) {
        Toast("请上传照片");
        return
      }

      if (!this.form.overseas_experience_new || (!!this.form.overseas_experience_new && this.form.overseas_experience_new.length <= 0)) {
        Toast("请上传中文教学经历证明");
        return
      }

      const param = {
        ...this.form,
        job_id: this.$route.query.job_id
      };
      try {
        param.other_material = JSON.stringify(param.other_material)
      } catch (error) {
        console.log(error)
      }



      if (this.status == 0 || this.status == 2 || this.status == 4) {
        if (!this.loading) {
          this.loading = true;
          this.$api.JOB_SIGN_STEP2(param).then(
            () => {
              this.loading = false;
              Toast("操作成功");
              // if (this.form.pay_status == 0) {
              //   this.$router.push({
              //     name: "Pay",
              //     query:{
              //       job_id:this.$route.query.job_id
              //     }
              //   });
              // }else{
              //   this.$router.push({
              //     name: "Index"
              //   });
              // }
              this.$router.push({
                name: "Index"
              });
            },
            () => {
              this.loading = false;
            }
          );
        }else{
          return
        }
      }

    },
    afterReadPhoto(file) {
      this.afterRead(file, 'photo')
    },
    afterReadGraduate(file) {
      this.afterRead(file, 'graduate')
    },
    afterReadDiploma(file) {
      this.afterRead(file, 'diploma')
    },
    afterReadenglish_level(file) {
      this.afterRead(file, 'english_level_new')
    },
    afterReadmandarin_certificate(file) {
      this.afterRead(file, 'mandarin_certificate')
    },
    afterReadoverseas_experience(file) {
      this.afterRead(file, 'overseas_experience_new')
    },
    afterReadbilingualism_resume(file) {
      this.afterRead(file, 'bilingualism_resume')
    },
    afterReadenglish_resume(file) {
      this.afterRead(file, 'english_resume')
    },
    afterReadvolunteer_certificate(file) {
      this.afterRead(file, 'volunteer_certificate_new')
    },
    afterReadteacher_certificate(file) {
      this.afterRead(file, 'teacher_certificate')
    },
    afterReaddriving_license(file) {
      this.afterRead(file, 'driving_license')
    },
    afterReadno_criminal_record(file) {
      this.afterRead(file, 'no_criminal_record')
    },
    afterReadtalent_show(file) {
      this.afterRead(file, 'talent_show')
    },
    afterReadother_material(file) {
      this.afterRead(file, 'other_material')
    },
    getUuidCode() {
      var charactors = "0123456789";
      var value = '', i;
      for (let j = 1; j <= 4; j++) {
        i = parseInt(10 * Math.random());
        value = value + charactors.charAt(i);
      }
      return value
    },
    // 上传图片
    async afterRead(file, type) {
      if (file.length && file.length > 0) {
        file.forEach(item => {
          this.uploadFunction(item, type)
        });
      } else {
        this.uploadFunction(file, type)
      }
    },

    async uploadFunction(file, type) {
      let _this = this
      let filename = file.file.name
      file.status = 'uploading';
      file.message = '上传中...';
      const tokenRes = await this.$api.QINIU_TOKEN();
      const nameKey = new Date().getTime() + this.getUuidCode() + filename.substr(filename.lastIndexOf("."))
      const config = {}
      const putExtra = {
        fname: filename
      }
      var observable = qiniu.upload(
        file.file,
        nameKey,
        tokenRes.uptoken,
        config,
        putExtra
      )
      var observer = {
        //   接收上传进度信息
        next() {
        },
        // 接收上传错误信息
        error() {
          file.status = 'failed';
          file.message = '上传失败';
          _this[`${type}Uploader`] = []

        },
        // 接收上传完成后的信息
        complete(res) {
          // https://qiniu.hanfengzhiyi.com/
          file.status = 'done';
          if (!_this.form.other_material) {
            _this.form.other_material = []
          }

          if (!_this.form.english_level_new) {
            _this.form.english_level_new = []
          }

          if (!_this.form.overseas_experience_new) {
            _this.form.overseas_experience_new = []
          }

          if (!_this.form.volunteer_certificate_new) {
            _this.form.volunteer_certificate_new = []
          }

          if (!_this.form.talent_show) {
            _this.form.talent_show = []
          }

          if (type == 'volunteer_certificate_new') {
            let item = {
              'file_path': `${res.key}`,
              'file_url': `https://qiniu.hanfengzhiyi.com/${res.key}`,
              'file_name': putExtra.fname
            }
            _this.form.volunteer_certificate_new.push(item)

          } else if (type == 'overseas_experience_new') {
            let item = {
              'file_path': `${res.key}`,
              'file_url': `https://qiniu.hanfengzhiyi.com/${res.key}`,
              'file_name': putExtra.fname
            }
            _this.form.overseas_experience_new.push(item)

          } else if (type == 'english_level_new') {
            let item = {
              'file_path': `${res.key}`,
              'file_url': `https://qiniu.hanfengzhiyi.com/${res.key}`,
              'file_name': putExtra.fname
            }
            _this.form.english_level_new.push(item)

          }  else if (type === 'talent_show') {
            let item = {
              'file_path': `${res.key}`,
              'file_url': `https://qiniu.hanfengzhiyi.com/${res.key}`,
              'file_name': putExtra.fname,
              'url':`https://qiniu.hanfengzhiyi.com/${res.key}`,
            }
            _this.form.talent_show.push(item)
          } else if (type === 'other_material') {
            let item = {
              'file_path': `${res.key}`,
              'file_url': `https://qiniu.hanfengzhiyi.com/${res.key}`,
              'file_name': putExtra.fname
            }
            _this.form.other_material.push(item)
          } else {
            var sourceLink = `${res.key}`
            _this.form[type] = sourceLink
            _this.form[`${type}_title`] = putExtra.fname
          }

        }
      };
      observable.subscribe(observer);
    }
  },
};
</script>

<style scoped  lang="scss">
.resume-page {
  padding-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.work-page {
  text-align: left;
  padding-bottom: 65px;
}

.work-page .title {
  padding: 10px 20px;
  margin: 10px 0 0 0;
}

.submit-content {
  margin-top: 20px;
  display: flex;
  padding: 0 10px;
}

.submit-btn {
  flex: 1;
  height: 44px;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-color: #e95944;
}

.submit-btn.disabled span {
  opacity: 0.5;
}

.submit-btn-cancel span {
  display: block;
  text-align: center;
  line-height: 44px;
}

.field-focus::after {
  border-color: #e95944;
  transform: scaleY(1);
}

::v-deep {
  .submit-btn.van-button--disabled {
    opacity: 1;
  }

  .submit-btn.van-button--disabled span {
    opacity: 0.5;
  }

  .van-cell {
    display: block;
    line-height: 28px;
    padding: 13px 20px;
    font-size: 16px;
    color: #333;
    border-color: #f1f4f6;
  }

  .van-cell__title {
    font-size: 12px;
    color: #666;
    padding-bottom: 5px;
  }

  .field-arrow .van-cell__value {
    position: relative;
    padding-right: 20px;
  }

  .field-arrow .van-cell__value::after {
    display: block;
    content: "";
    width: 19px;
    height: 16px;
    background: url(../../assets/images/mine/icon-arrow.png);
    background-size: 19px 16px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 8px 0;
  }

  .van-picker__confirm {
    color: #e95944;
  }

  .van-field__label {
    width: 28em;
  }

  .photo {

    .van-uploader__upload,
    .van-uploader__preview-image {
      width: 74px;
      height: 103px;
    }
  }
}

.form-tips {
  text-align: left;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}



@media (min-width: 992px) {
  .form-tips {
    text-align: left;
    font-weight: bold;
    padding-left: 15px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .resume-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .submit-btn {
    max-width: 200px;
    margin: 0 auto;
  }

  ::v-deep {
    .photo {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 148px !important;
        height: 206px !important;
      }
    }

    .upload-image {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 200px !important;
        min-height: 100px !important;
        height: auto !important;
      }

    }

    .upload-video{
      .van-uploader__preview .van-uploader__file{
        width: 200px !important;
        min-height: 200px !important;
      }
    }

    .uploader-file {

      .van-uploader__file,
      .van-uploader__preview {
        width: 600px !important;
        height: 80px !important;
      }
    }

    .van-field__label {
      width: 40em !important;
    }
  }

}
</style>
